import { debounce } from '../../js/utils/debounce';

var $ = jQuery.noConflict();

var fallbackImage = null;

$(window).on('load', function () {
    let method = 'front';
    if ($('body').hasClass('wp-admin')) method = 'wp-admin';

    const revertToFallback = debounce(function (callFunction) {
        console.log('revertToFallback', { callFunction });
        if (callFunction === false) return;

        $('.block-list-banner')
            .find('.block-list-banner__bg-image img')
            .removeClass('active');
        fallbackImage.classList.add('active');
    }, 250);

    var initializeBlock = function ($block) {
        console.log('initializeBlock banner');

        var elem = $('.block-list-banner');
        fallbackImage = elem
            ? elem[0].querySelector('.block-list-banner__bg-image img.active')
            : null;

        console.log(fallbackImage);

        $('.block-list-banner .single-item-content').hover(
            function mouseEnter() {
                revertToFallback(false);
                var img_url = $(this).attr('data-bg-image');
                var img_class =
                    '.block-list-banner__bg-image img[src="' + img_url + '"]';
                if (typeof img_url !== 'undefined') {
                    elem.find('.block-list-banner__bg-image img').removeClass(
                        'active'
                    );
                    elem.find(img_class).addClass('active');
                }
            },
            // Mouse Leave
            function mouseLeave() {
                if (fallbackImage) {
                    revertToFallback();
                }
            }
        );
    };

    window.onload = (function () {
        initializeBlock($(this));
    })();

    if (window.acf) {
        window.acf.addAction(
            'render_block_preview/type=list-banner',
            initializeBlock
        );
    }
});
